import { Nav, Navbar, Container } from 'react-bootstrap';
import logo from '../../assets/TransparentLogolightblue.png'
import { FaHome, FaPuzzlePiece, FaBrain, FaCogs, FaProjectDiagram, FaMugHot } from "react-icons/fa";
import './header.css';

function Header() {
    return (
        <div >
            <Navbar bg="white" fixed="top">
                <Navbar.Brand href="/home">
                        <img src={logo} height="40" className="logo-light-mode" alt="" />
                    </Navbar.Brand>
                <Container className="center">
                    <Navbar.Toggle aria-controls='basic-navbar-nav'>
                    </Navbar.Toggle>
                    <Nav className="justify-content-center" style={{ flex: 1, fontSize: '16px' , fontWeight: "bold", color: "00B0F0" }}>
                        <Nav.Link href="#home"><FaHome color="00B0F0"/>{" "}Home</Nav.Link>
                        <Nav.Link href="#products"><FaCogs color="00B0F0"/>{" "}Products</Nav.Link>
                        <Nav.Link href="#services"><FaPuzzlePiece color="00B0F0"/>{" "}Services</Nav.Link>
                        <Nav.Link href="#projects"><FaProjectDiagram color="00B0F0"/>{" "}Projects</Nav.Link>
                        <Nav.Link href="#about"><FaBrain color="00B0F0"/>{" "}About</Nav.Link>
                        <Nav.Link href="#contact"><FaMugHot color="00B0F0"/>{" "}Contact</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>

        </div>
    );
}

export default Header;

/*
<NavDropdown title="Vloeren" id="collasible-nav-dropdown">
<NavDropdown.Item href="/vloeren">Overzicht</NavDropdown.Item>
<NavDropdown.Item href="/hardevloeren">Harde vloeren</NavDropdown.Item>
<NavDropdown.Item href="/flexibelevloeren">Flexibele vloeren</NavDropdown.Item>
<NavDropdown.Item href="/zachtevloeren">Zachte vloeren</NavDropdown.Item>
</NavDropdown>
<NavDropdown title="Onderhoud" id="collasible-nav-dropdown">
<NavDropdown.Item href="/behandelingen">Groot vloeren onderhoud</NavDropdown.Item>
<NavDropdown.Item href="/behandelingen">Periodiek vloeren onderhoud</NavDropdown.Item>
<NavDropdown.Item href="/behandelingen">Onderhoud marmer vloeren</NavDropdown.Item>
</NavDropdown>
*/