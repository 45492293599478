import bgImageHeader from "../../assets/lightblueheader.jpg";
import textlogo from '../../assets/TransparentLogolightblue.png'
import { FaMugHot } from "react-icons/fa";
import "./contact.css";

//FaMugHot
function Contact() {
    return (
        <div id="contact" >
            <div class="home-slider position-relative">
                <section className="bg-half d-table w-100" style={{backgroundImage: `url(${bgImageHeader})`, backgroundSize: "100% 90%"}}>
                </section>
                <section >
                    <div className="col-12 text-center">
                         <h1 className="title mb-8" ><FaMugHot color="00B0F0"/>{" "}<b>Contact</b>{" "}<img src={textlogo} height="50" className="logo-light-mode" alt="" /></h1>
                    </div>
                </section>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 ">
                        <h5 className="text-muted mx-auto"><br />At <img src={textlogo} height="30" className="logo-light-mode" alt="" />, we believe in forging enduring partnerships with our clients. Your aspirations drive our innovation, and together, we can traverse the pathway to success. Whether you have a query, require assistance, or are ready to take your business to new heights, our dedicated team is here to assist.</h5>
                        <h5 className="text-muted mx-auto"><br />Reach out to us through the following channel, and let’s commence this journey of collaborative growth:</h5>
                        <br />
                    </div>
                    <div className="col-12">    
                        <h5 className="text-muted mx-auto"><b>Email</b><br />
                            <a href="mailto:info@lightbluegroup" class="text-black fw-bold" color="00B0F0">info@lightbluegroup</a>
                        </h5>
                        <h5 className="text-muted mx-auto"><br />Let’s connect, collaborate, and create a future filled with endless possibilities. Your journey towards excellence begins with a simple conversation. Contact us today, and let’s shape tomorrow, together.</h5>
                        <br />
                        <br />
                    </div>
                </div>
            </div>

        </div>

    );
}

export default Contact;

