import bgImageHeader from "../../assets/lightblueheader.jpg";
import textlogo from '../../assets/TransparentLogolightblue.png'
import servicesAzureData from "../../data/servicesazure.json";
import servicesFinCrimeData from "../../data/servicesfincrime.json";
import servicesManagement from "../../data/servicesproject.json";
import { FaPuzzlePiece, FaCamera } from "react-icons/fa";
import "./services.css";

const Services = () => {
    return (
        <div id="services" >
            <div class="home-slider position-relative">
                <section className="bg-half d-table w-100" style={{backgroundImage: `url(${bgImageHeader})`, backgroundSize: "100% 90%"}}>
                </section>
                <section >
                    <div className="col-12 text-center">
                         <h1 className="title mb-8" ><FaPuzzlePiece color="00B0F0"/>{" "}<img src={textlogo} height="50" className="logo-light-mode" alt="" /><b> services</b></h1>
                         <br />
                    </div>
                </section>
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title mb-4 pb-2">
                            <h6 className="text-black">Technical (Azure) services <img src={textlogo} height="30" className="logo-light-mode" alt="" /> can deliver. <br /><br /></h6>
                        </div>
                    </div>
                    {/* Azure Project cards */}
                    <div className="row justify-content-center">
                        {servicesAzureData.map((azure,i) => {
                            return (
                                <div className="col-lg-6 col-md-6">
                                    <div className="text-center">
                                        <span className="text-black text-center h5 ">{azure.name}</span>
                                        <div className="position-relative">
                                            <p className="text-muted para-desc mb-0 mx-auto"><br />{azure.description}</p>
                                        </div>
                                        <div className="card-body content">
                                        </div>
                                        <div className="author">
                                            <small className="text-light user d-block"><FaCamera /> lightblue</small>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <br /><br /><br />
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title mb-4 pb-2">
                            <h6 className="text-black">Program and Project services <img src={textlogo} height="30" className="logo-light-mode" alt="" /> can deliver. <br /><br /></h6>
                        </div>
                    </div>
                    {/* Management cards */}
                    <div className="row justify-content-center">
                        {servicesManagement.map((prj,i) => {
                            return (
                                <div className="col-lg-6 col-md-6">
                                    <div className="text-center">
                                        <span className="text-black text-center h5 ">{prj.name}</span>
                                        <div className="position-relative">
                                            <p className="text-muted para-desc mb-0 mx-auto"><br />{prj.description}</p>
                                        </div>
                                        <div className="card-body content">
                                        </div>
                                        <div className="author">
                                            <small className="text-light user d-block"><FaCamera /> lightblue</small>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <br /><br /><br />
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title mb-4 pb-2">
                            <h6 className="text-black">Please find below financial crime (fighting) services <img src={textlogo} height="30" className="logo-light-mode" alt="" /> can deliver. <br /><br /></h6>
                        </div>
                    </div>
                    {/* Fincrime cards */}
                    <div className="row justify-content-center">
                        {servicesFinCrimeData.map((fincrime,i) => {
                            return (
                                <div className="col-lg-6 col-md-6">
                                    <div className="text-center">
                                        <span className="text-black text-center h5 ">{fincrime.name}</span>
                                        <div className="position-relative">
                                            <p className="text-muted para-desc mb-0 mx-auto"><br />{fincrime.description}</p>
                                        </div>
                                        <div className="card-body content">
                                        </div>
                                        <div className="author">
                                            <small className="text-light user d-block"><FaCamera /> lightblue</small>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services