import bgImageHeader from "../../assets/lightblueheader.jpg";
import textlogo from '../../assets/TransparentLogolightblue.png'
import projectsData from "../../data/projects.json";
import Project1 from "../../assets/projects/francineballet.jpg";
import Project2 from "../../assets/projects/msovloeren.jpg";
import Project3 from "../../assets/projects/toposchoonmaak.jpg";
import Project4 from "../../assets/projects/losfuisos.jpg";
import { FaProjectDiagram } from "react-icons/fa";
import "./projects.css";

const projects = [ Project1, Project2, Project3, Project4 ];

const Projects = () => {
    return (
        <div id="projects" >
            <div class="home-slider position-relative">
                <section className="bg-half d-table w-100" style={{backgroundImage: `url(${bgImageHeader})`, backgroundSize: "100% 90%"}}>
                </section>
                <section >
                    <div className="col-12 text-center">
                         <h1 className="title mb-8" ><FaProjectDiagram color="00B0F0"/>{" "}<img src={textlogo} height="50" className="logo-light-mode" alt="" /><b> projects</b></h1>
                    </div>
                </section>
            </div>

            <div className="container">
                
            </div>

            <div className="container">
            <div className="row justify-content-center">
                    <div className="col-12 ">
                        <h5 className="text-muted mx-auto"><br />At <img src={textlogo} height="30" className="logo-light-mode" alt="" />, we believe in the transformative power of technology to streamline operations, drive innovation, and foster growth. Our journey began with a simple, yet profound vision - to extend sophisticated software solutions to Small and Medium-sized Enterprises (SMEs), enabling them to thrive in a competitive marketplace. Our founders, with their rich tapestry of experience in IT, Media, and combatting financial crime across international landscapes, envisioned a realm where top-tier software solutions are accessible to businesses of all scales.</h5>
                        <h5 className="text-muted mx-auto"><br /><b>Our Core: <img src={textlogo} height="30" className="logo-light-mode" alt="" /> Business Verticals</b><br />Navigating the digital realm can be a complex endeavor, especially when venturing into new market territories. <img src={textlogo} height="30" className="logo-light-mode" alt="" /> Business Verticals is our answer to this challenge. It’s more than just a service; it’s a robust platform designed to swiftly deploy business applications on Azure at highly competitive terms. Tailored to be highly adaptable, our platform molds itself to your specific business and application needs, making it a remarkably flexible solution in propelling your business forward.</h5>
                        <h5 className="text-muted mx-auto"><br /><b>Your Competitive Edge</b><br />Embarking on a new market venture often comes with its set of hurdles, especially when there’s a gap in technical expertise or experience in development and market deployment. <img src={textlogo} height="30" className="logo-light-mode" alt="" /> bridges this gap, providing a solid foundation for building and nurturing your ideas from inception to market realization. We extend beyond just service provision to being your steadfast partner in navigating the technical terrain, ensuring your venture into new markets is smooth and triumphant.</h5>
   
                    </div>
                </div>
                <div className="row justify-content-center">
                    {/* Project cards */}
                    <div className="row justify-content-center">
                        {projectsData.map((project,i) => {
                            return (
                                <div className="col-lg-6 col-md-6">
                                    <div className="text-center">
                                        <span className="text-black text-center h5 ">{project.name}</span>
                                        <div className="position-relative">
                                            <p className="text-muted para-desc mb-0 mx-auto"><br />{project.description}</p>
                                            <a href={project.url} target="_blank" rel="noreferrer">
                                                <img src={projects[i]} className="card-img-top rounded" width="300px" height="300px" alt={project.name}  />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="col-12 ">
                    <h5 className="text-muted mx-auto"><br /><b>Customized to Your Brand</b><br />Our array of highly standardized products are designed to seamlessly align with your brand identity. With an option for personalized branding coupled with reasonable pricing, we deliver solutions that are a blend of value, functionality, and brand coherence.</h5>
                    <h5 className="text-muted mx-auto"><br /><b>Beyond Standardization</b><br />At Lightblue, we understand that each business is unique with its set of demands. Hence, our standardized products are just the starting point. They can be meticulously tailored to meet the distinctive needs of your business or a consortium of organizations aiming to leverage the benefits of standardized software in their specialized business domain.<br />Your success is the measure of our excellence. At <img src={textlogo} height="30" className="logo-light-mode" alt="" />, we are not just service providers; we are your partners in achieving remarkable business milestones. Discover the synergy of your vision powered by our innovative solutions. Reach out to us and let’s embark on a journey of collaborative growth and remarkable market success. Together, we’ll shape the future of your business, ensuring a significant market imprint. Contact us today, and let’s pioneer the new era of business excellence, together.</h5>
                    <br />
                </div>
            </div>
        </div>
    );
}

export default Projects