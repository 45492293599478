import bgImageTop from "../../assets/lightbluewebsitebackground.jpg";
import textlogo from '../../assets/TransparentLogolightblue.png'
import { FaCaretSquareUp} from "react-icons/fa";
import "./home.css";

function Home() {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // for smoothly scrolling
        });
      };

    return (
        <div id="home" className='h-screen w-half flex justify-center items-center'>
            <section className="bg-half d-table w-100" style={{backgroundImage: `url(${bgImageTop})`, backgroundSize: "100% 100%"}}>
                <div className="col-12 text-center align-bottom ">
                    <br /><br /><br /><br /><br /><br />
                    <h1 className="display-3 title-dark fw-bold text-black  ">Welcome to <img src={textlogo} height="80" className="logo-light-mode" alt="" /></h1>
                    <h4 className="mx-auto fw-bold text-black ">Solution provider for small and medium sized businesses. </h4>
                </div>
            </section>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 ">
                        <h5 className="text-muted mx-auto"><br />In today’s fast-paced business arena, maneuvering through IT challenges requires a blend of innovation, expertise, and robust software solutions. This is where <img src={textlogo} height="30" className="logo-light-mode" alt="" /> steps in, a name synonymous with excellence, born out of extensive experience in IT, Media, and combatting financial crime across global platforms. <img src={textlogo} height="30" className="logo-light-mode" alt="" /> delivers a platform where Small and Medium-sized Enterprises (SMEs) are empowered with high-caliber software solutions, previously the forte of large corporations.</h5>
                        <h5 className="text-muted mx-auto"><br />Discover the <img src={textlogo} height="30" className="logo-light-mode" alt="" /> advantage today. Our dedication is not just to meet your expectations but to exceed them, providing an unparalleled pathway to achieving your business aspirations. Reach out to us and let’s embark on a journey to elevate your business to the pinnacle of success. Your vision, powered by our innovation, is the formula for a remarkable market presence. Contact us today, and let's build tomorrow, together.</h5>
                        <br />
                    </div>
                </div>
            </div>


            <div className="row justify-content-right">
                <div className="col-12 text-center">
                    <button onClick={scrollToTop} className="btn btn-icon btn-primary back-to-top"><FaCaretSquareUp /> Naar Boven</button>
                </div>
            </div>



            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="text-center">
                            <span className="text-black text-center h4 ">Completely on Azure</span>
                            <div className="card-body content">
                                <p className="text-muted para-desc mb-0 mx-auto"><br />lightblue business verticals - the fastest path to business applications on Azure at very attractice conditions. Our Business Verticals platform is very adaptable to your specific business and application needs.</p>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="text-center">
                            <span className="text-black text-center h4 ">Your Advantage</span>
                            <div className="card-body content">
                                <p className="text-muted para-desc mb-0 mx-auto"><br />Want to enter a market with a new an fresh business proposition, but you lack the technical expertise, or do not have experience in development and bringing it to market. Then lightblue Business Verticals is the starting point for building out your ideas. Get in contact with us today.</p>
                                <br />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-right">
                <div className="col-12 text-center">
                    <button onClick={scrollToTop} className="btn btn-icon btn-primary back-to-top"><FaCaretSquareUp /> Naar Boven</button>
                </div>
            </div>
        </div>
    );
}

export default Home;



/*

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <br /><br />
                        <div className="section-title text-center mb-4 pb-2">
                            <span className="text-primary text-center h2 ">Enkele door ons onderhouden vloeren...</span>
                        </div>
                    </div>
                </div>
          
                
                <div className="row">
                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="card blog rounded border-0 shadow">
                            <div className="position-relative">
                                <img src={require("../../images/greenfloor_mso.jpg")} className="card-img-top rounded" width="150px" height="300px" alt="..."/>
                            <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><FaCamera /> MSO Vloeren</small>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="card blog rounded border-0 shadow">
                            <div className="position-relative">
                                <img src={require("../../images/polishedreception_mso.jpg")} className="card-img-top rounded" width="150px" height="300px" alt="..."/>
                            <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><FaCamera /> MSO Vloeren</small>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="card blog rounded border-0 shadow">
                            <div className="position-relative">
                                <img src={require("../../images/officefloor_mso.jpg")} className="card-img-top rounded" width="150px" height="300px" alt="..."/>
                            <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><FaCamera /> MSO Vloeren</small>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="card blog rounded border-0 shadow">
                            <div className="position-relative">
                                <img src={require("../../images/oldwoodenstrairs_mso.jpg")} className="card-img-top rounded" width="150px" height="300px" alt="..."/>
                            <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><FaCamera /> MSO Vloeren</small>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="card blog rounded border-0 shadow">
                            <div className="position-relative">
                                <img src={require("../../images/receptionfloor2_mso.jpg")} className="card-img-top rounded" width="150px" height="300px" alt="..."/>
                            <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><FaCamera /> MSO Vloeren</small>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="card blog rounded border-0 shadow">
                            <div className="position-relative">
                                <img src={require("../../images/sportsfloor_mso.jpg")} className="card-img-top rounded" width="150px" height="300px" alt="..."/>
                            <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><FaCamera /> MSO Vloeren</small>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="card blog rounded border-0 shadow">
                            <div className="position-relative">
                                <img src={require("../../images/livingfloor_mso.jpg")} className="card-img-top rounded" width="150px" height="300px" alt="..."/>
                            <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><FaCamera /> MSO Vloeren</small>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="card blog rounded border-0 shadow">
                            <div className="position-relative">
                                <img src={require("../../images/livingfloor2_mso.jpg")} className="card-img-top rounded" width="150px" height="300px" alt="..."/>
                            <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><FaCamera /> MSO Vloeren</small>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="card blog rounded border-0 shadow">
                            <div className="position-relative">
                                <img src={require("../../images/doorpost_mso.jpg")} className="card-img-top rounded" width="150px" height="300px" alt="..."/>
                            <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><FaCamera /> MSO Vloeren</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title mb-4 pb-2">
                            <br />
                            <br />
                            <h4 className="title mb-4">Maak een afspraak met ons</h4>
                            <p className="text-muted para-desc mx-auto mb-0">Loop samen met ons door de diensten van <span className="text-primary fw-bold">MSO Vloeren</span> en waar we u verder ter dienst kunnen zijn.</p>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <a href="contact.html" className="btn btn-pills btn-primary">Neem contact op</a>
                    </div>
                </div>
                <br />
                <br />

                <div className="row justify-content-right">
                    <div className="col-12 text-center">
                    <button onClick={scrollToTop} className="btn btn-icon btn-primary back-to-top"><FaCaretSquareUp /> Naar Boven</button>
                    </div>
                </div>
                <br />
                <br />
            </div>
            


*/