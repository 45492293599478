import bgImageHeader from "../../assets/lightblueheader.jpg";
import textlogo from '../../assets/TransparentLogolightblue.png'
import { FaBrain } from "react-icons/fa";
import "./about.css";

const About = () => {
    return (
        <div id="about" >

            <div class="home-slider position-relative">
                <section className="bg-half d-table w-100" style={{backgroundImage: `url(${bgImageHeader})`, backgroundSize: "100% 90%"}}>
                </section>
                <section >
                    <div className="col-12 text-center">
                         <h1 className="title mb-8" ><FaBrain color="00B0F0"/>{" "}<b>About</b>{" "}<img src={textlogo} height="50" className="logo-light-mode" alt="" /><b> management</b></h1>
                         <br />
                    </div>
                </section>
            </div>

            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 ">
                        <h5 className="text-muted mx-auto"><br />At Lightblue, we believe in the transformative power of technology to streamline operations, drive innovation, and foster growth. Our journey began with a simple, yet profound vision - to extend sophisticated software solutions to Small and Medium-sized Enterprises (SMEs), enabling them to thrive in a competitive marketplace. Our founders, with their rich tapestry of experience in IT, Media, and combatting financial crime across international landscapes, envisioned a realm where top-tier software solutions are accessible to businesses of all scales.</h5>
                        <br />
                        <br />
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="card blog rounded border-0 shadow">
                            <span className="text-black text-center h4 ">Steven van Rossen</span>
                            <div className="card-body content">
                                <p className="text-muted para-desc mb-0 mx-auto"><br /> Co-founder of lightblue, Steven has extensive experience with solution design and software development in Finance, Telecom and Media.<br />
                    Leveraging innovative cloud technology, agile development approaches and best practises Steven engages with lightblue customers to realize their cloud application ambition.<br />
                    Before lightblue, Steven held international consultancy, product delivery management and chief operating officer positions in other successful innovative software and technology companies.<br />
                    Steven has a Master in Applied Economics from the University of Antwerp.</p>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="card blog rounded border-0 shadow">
                            <span className="text-black text-center h4 ">Dave Dekkers</span>
                            <div className="card-body content">
                                <p className="text-muted para-desc mb-0 mx-auto"><br /> Co-founder of lightblue, Dave is a vetted professional in the field of fighting financial crime (AML, Sanctions, Complaince, KYC, Anti-Fraud, Cybercrime, Forensics, Accounting) and specialized in analysis and intelligence gathering.<br />
                His international experience allows Dave to think-out-of-box and be prepared for any given challenge.<br />
                Before lightblue, Dave held positions as Head of Product Management, Senior Business consultancy and several other senior positions at various lrage financial institutes and software companies worldwide.</p>
                                <br />
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About


