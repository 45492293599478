import bgImageHeader from "../../assets/lightblueheader.jpg";
import textlogo from '../../assets/TransparentLogolightblue.png'
import businessverticals from "../../assets/projects/businessverticals.jpg";
import lbbvecommerce from "../../assets/projects/lbbvecommerce.jpg";
import { FaCogs } from "react-icons/fa";
import "./products.css";

const Products = () => {
    return (
        <div id="products" >
            <div class="home-slider position-relative">
                <section className="bg-half d-table w-100" style={{backgroundImage: `url(${bgImageHeader})`, backgroundSize: "100% 90%"}}>
                </section>
                <section >
                    <div className="col-12 text-center">
                         <h1 className="title mb-8" ><FaCogs color="00B0F0"/>{" "}<img src={textlogo} height="50" className="logo-light-mode" alt="" /><b> products</b></h1>
                         
                    </div>
                </section>
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 ">
                        <h5 className="text-muted mx-auto"><br />Introducing <img src={textlogo} height="30" className="logo-light-mode" alt="" /> Business Verticals - your expressway to deploying business applications on Azure swiftly, and at highly attractive terms. Our platform isn’t just a service, it’s an adaptable solution finely tuned to meet your business’s unique needs and application demands.</h5>
                        <h5 className="text-muted mx-auto"><br />Embarking on a new market venture with a fresh business proposition can be daunting, especially when there’s a lack of technical expertise or experience in development and market deployment. <img src={textlogo} height="30" className="logo-light-mode" alt="" /> Business Verticals is your starting point, a springboard to transform your innovative ideas into viable market solutions. We are not just a service provider; we are your partner in navigating the technical landscape, ensuring your market entry is seamless and successful.</h5>
                        <br />
                    </div>
                </div>
            </div>
            
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-lg-6 col-md-6">
                        <div className="text-center">
                            <span className="text-black text-center h4 ">Business Verticals</span>
                            <div className="position-relative">
                                <img src={businessverticals} className="card-img-top rounded" width="600px" height="300px" alt="..." />
                                <p className="text-muted para-desc mb-0 mx-auto"><br />SME Template for building SME customized solutions</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="text-center">
                            <span className="text-black text-center h4 ">lbbv eCommerce</span>
                            <div className="position-relative">
                                <img src={lbbvecommerce} className="card-img-top rounded" width="600px" height="300px" alt="..." />
                                <p className="text-muted para-desc mb-0 mx-auto"><br />Demo lightblue eCommerce Webshop</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 ">
                        <h5 className="text-muted mx-auto"><br />Our offerings extend to a suite of highly standardized products, tailored to echo your brand’s identity. This personalized branding aspect, coupled with our reasonable pricing, makes our solutions an epitome of value and functionality.</h5>
                        <h5 className="text-muted mx-auto"><br />With <img src={textlogo} height="30" className="logo-light-mode" alt="" />, customization is not an afterthought. Our standardized products, the core of our business verticals, can be meticulously built out to cater to specialized business needs. Whether you are an individual organization or a consortium aiming to harness the advantages of standardized software in your specialized business domain, we have the blueprint to deliver precise solutions.</h5>
                        <h5 className="text-muted mx-auto"><br />Discover the <img src={textlogo} height="30" className="logo-light-mode" alt="" /> advantage today. Our dedication is not just to meet your expectations but to exceed them, providing an unparalleled pathway to achieving your business aspirations. Reach out to us and let’s embark on a journey to elevate your business to the pinnacle of success. Your vision, powered by our innovation, is the formula for a remarkable market presence. Contact us today, and let's build tomorrow, together.</h5>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products