import React from "react";
import Header from './components/header/header';
import Home from './components/home/home';
import Products from './components/products/products';
import Services from "./components/services/services";
import Projects from './components/projects/projects';
import About from './components/about/about';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';

function App() {

  return (
    <div className="App">
      <Header />
      <Home />
      <Products />
      <Services />
      <Projects />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}
// https://dev.to/salehmubashar/react-router-dom-36a2
// https://discover.hubpages.com/technology/React-Router-V6-The-Main-Changes
// https://github.com/react-bootstrap/react-router-bootstrap
export default App;

